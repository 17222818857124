/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Styled } from "theme-ui";
import { Link } from "gatsby";
import Img from "gatsby-image";

const componentStyles = ({
  colors: { background, primary, text },
  fontSizes: [, , , , fontSizeFive]
}) =>
  css`
    position: relative;
    display: flex;
    flex-direction: column;
    color: #fff;
    background: ${background};
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: ${fontSizeFive};
    letter-spacing: 1px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    will-change: auto;
    overflow: hidden;

    border-radius: 16px;
    text-align: center;
    font-weight: 700;
    text-shadow: 1px 1px 4px black;

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background: rgba(0, 0, 0, 0.2);
      transition: 0.2s ease;
      opacity: 0;
    }

    &:hover,
    &:focus {
      color: #fff;
    }

    &:hover {
      img {
        transform: scale(1.10);
      }
      .overlay {
        opacity: 1;
      }
    }
  `;

const imageWrapperStyles = css`
  flex: 1 1 80%;

  .gatsby-image-wrapper {
    height: 100%;
    > div {
      padding-bottom: 0 !important;
    }

    img {
      will-change: auto;
      transition: 0.2s ease !important;
      transform-origin: center;
    }
  }
`;

const titleWrapperStyles = ({ fontSizes, sizes }) => css`
  text-transform: capitalize;
  font-size: ${fontSizes[4]};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;

  position: absolute;
  bottom: 10px;
  width: 100%;
  left: 0;
  font-family: 'Permanent Marker';

  @media (min-width: ${sizes.md}px) {
    font-size: ${fontSizes[5]};
  }

  @media (min-width: ${sizes.lg}px) {
    font-size: ${fontSizes[6]};
  }
`;

const RecipeCard = ({ recipe: { title, slug, image }, ...props }) => {
  return (
    <Styled.a
      as={Link}
      to={`/${slug}`}
      css={theme => componentStyles(theme)}
      {...props}
    >
      <div css={imageWrapperStyles}>
        <div className="overlay"></div>
        <Img fluid={image.sharp.fluid} />
      </div>
      <div css={theme => titleWrapperStyles(theme)}>{title}</div>
    </Styled.a>
  );
};

export default RecipeCard;
