/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Link } from "gatsby";
import { Button } from "@marinda/react-styled-buttons";

const componentCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const buttonCSS = theme => css`
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  margin: 6px 8px;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  line-height: 26px;
  min-width: 80px;
  transition: all .1s ease-out;
  font-size: 1.25rem;
  font-family: 'CaveatBrush';
  color: ${theme.colors.tags.text};
  background: ${theme.colors.tags.background};

  &:hover {
    color: ${theme.colors.background};
    background: ${theme.colors.tags.selected};
    filter: drop-shadow(0 0 0.15rem #808b96);
    box-shadow: unset;
  }
    
  &.active {
    color: ${theme.colors.background};
    background: ${theme.colors.tags.selected};
  }
`;

const Tags = ({ basePath = "/", tags = [] }) => {
  return (
    <nav css={componentCSS}>
      {
        <Button disableAnimation
          key="all"
          css={theme => buttonCSS(theme)}
          as={Link}
          to={basePath}
          size="small"
          activeClassName="active"
        >
          Tout
        </Button>
      }
      {tags &&
        tags.map(({ tag }) => (
          <Button disableAnimation
            key={tag}
            css={theme => buttonCSS(theme)}
            as={Link}
            to={`/tags/${tag}`}
            size="small"
            partiallyActive={true}
            activeClassName="active"
          >
            {tag}
          </Button>
        ))}
    </nav>
  );
};

export default Tags;
